import { useState, useEffect } from "react";
import { AthleteRoleEnum, GetAvatarCategoryEnum } from "@/api/generated";
import { useAvatar } from "@/hooks/api/use-avatar";
import { useAthlete } from '@/hooks/api/use-athlete';
import { AthleteSummaryCard } from '@/types/athlete';
import { useRecoilValue } from 'recoil';
import { sportDictionaryState } from '@/atoms/sport/sport-dictionary';
import { useParams } from 'react-router-dom';


export const useAthleteData = () => {
  const [data, setData] = useState<AthleteSummaryCard>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id } = useParams();
  const sportDictionary = useRecoilValue(sportDictionaryState);
  const { getAthlete } = useAthlete();
  const { byCategoryAndIdUrl } = useAvatar();

  const fetchData = async () => {
    setIsLoading(() => true);
    const idNum = Number(id);
    const data = await getAthlete(idNum);
    const avatarUrl = await byCategoryAndIdUrl(
      GetAvatarCategoryEnum.Account,
      idNum,
    );
    if (data) {
      setData({
        ...data,
        avatar: avatarUrl,
        location: data.location,
        rating: 4,
        achievements: ['Bronze', 'Silver', 'Gold', 'Regular'],
        sports: data.sportIds.map((sportId) => sportDictionary.get(sportId)?.name || ''),
        role: data.isCoach ? AthleteRoleEnum.Coach : AthleteRoleEnum.Athlete,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return {
    data,
    isLoading,
  };
};
