import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { athleteSchema } from "@/schemas/athlete.schema";
import { Athlete, AthleteSummaryCard } from "@/types/athlete";
import { useRecoilValue } from "recoil";
import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import { Dropdown } from "@/components/dropdown";
import { FC } from "react";

type EditProfileSelfFormProps = {
  defaultValues?: AthleteSummaryCard;
};

export const EditPrivacyProfileForm: FC<EditProfileSelfFormProps> = ({
  defaultValues,
}) => {
  const form = useForm<Athlete>({
    resolver: zodResolver(athleteSchema),
    defaultValues: {
      ...defaultValues,
      location: defaultValues?.location.position,
    },
  });
  const sportDictionary = useRecoilValue(sportDictionaryState);

  const onSubmit = async (values: Athlete) => {
    try {
      console.log("values: ", values);
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <Dropdown
          name="message-me"
          isMulti
          placeholder="Кто может мне писать"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        <Dropdown
          name="invite-me"
          isMulti
          placeholder="Кто может приглашать меня на тренировки"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        <Dropdown
          name="view-me"
          isMulti
          placeholder="Кто может видеть мой профиль"
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
          className="mt-2"
          options={Array.from(sportDictionary.values())}
        />
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <div className="flex justify-center gap-2.5 py-4">
          <Button type="submit">Сохранить</Button>
          <Button variant="outlineMain" type="reset">
            Отмена
          </Button>
        </div>
      </form>
    </Form>
  );
};
