import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { Input } from "@/components/rhf/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Image } from "@/icons/image";
import { eventSchema, externalEventSchema } from "@/schemas/event.schema";
import { Event, EventFormData, ExternalEvent } from "@/types/event";
import { Checkbox } from "@/components/rhf/checkbox";
import { FC } from "react";
import { useEvent } from "@/hooks/api/use-event";
import { DatePicker } from "@/components/rhf/date-picker";
import { LocationInput } from "@/components/rhf/location-input";
import { LocationIcon } from "@/icons/location";
import { useLocationLabelData } from "@/hooks/use-location-label-data";

type EditEventFormProps = {
  external?: boolean;
  defaultValues: EventFormData;
};

export const EditEventForm: FC<EditEventFormProps> = ({
  external,
  defaultValues,
}) => {
  const { updateEvent, updateExternalEvent } = useEvent();
  const currentSchema = external ? externalEventSchema : eventSchema;

  const form = useForm<EventFormData>({
    resolver: zodResolver(currentSchema),
    defaultValues,
  });

  const { locationName, isLoading } = useLocationLabelData(form);

  const onSubmit = async (values: EventFormData) => {
    try {
      console.log("values: ", values);
      const result = external
        ? await updateExternalEvent(values as ExternalEvent)
        : await updateEvent(values as Event);
      // return navigate("/login");
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        {external && (
          <div className="mb-2">
            <Input
              name="externalEventUrl"
              controlElementClassName="mt-2"
              labelClassName="font-bold"
              placeholder="*Ссылка на событие"
            />
            <Input
              name="externalRegistrationUrl"
              controlElementClassName="mt-2"
              labelClassName="font-bold"
              placeholder="*Ссылка на регистрацию"
            />
          </div>
        )}
        <div className="flex relative">
          {form.getValues().avatar && (
            <Image
              src={form.getValues().avatar}
              className="absolute left-2/4 -translate-x-2/4 w-16 z-0 top-2/4 -translate-y-2/4"
              alt="Avatar"
            />
          )}
          <Input
            name="avatar"
            type="file"
            itemClassName="m-auto z-10 relative"
            labelClassName="font-bold"
            placeholder="Изображение события"
            onValueChange={(file) => {
              console.log("avatar prop updated with: ", file);
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            name="name"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="*Название"
          />
          <Input
            name="sport"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="*Вид спорта"
          />
        </div>
        <LocationInput
          name="location"
          valueLabel={locationName}
          isLoading={isLoading}
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
          startAdornment={<LocationIcon className="w-4 h-4 !top-2" />}
        />
        <DatePicker
          name="date"
          placeholder="*Время"
          controlElementClassName="mt-2"
        />
        <Input
          name="description"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="Описание"
        />
        <Input
          name="socialMedia"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="Соцсети"
        />
        <Input
          name="distance"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="Дистанция"
        />
        <div className="flex gap-4 justify-center mt-2">
          <div className="flex-1 py-1 px-4 border border-blue-200 rounded-lg">
            <Checkbox
              name="isPublic"
              itemClassName="flex"
              checked={!!form.getValues().isPublic}
              onValueChange={(e) => {
                form.setValue("isPublic", !!e);
                form.trigger("isPublic");
              }}
            >
              <span className="ft1">Публичное</span>
            </Checkbox>
          </div>
          <div className="flex-1 py-1 px-4 border border-blue-200 rounded-lg">
            <Checkbox
              name="public"
              itemClassName="flex"
              checked={!form.getValues().isPublic}
              onValueChange={(e) => {
                form.setValue("isPublic", !e);
                form.trigger("isPublic");
              }}
            >
              <span className="ft1">Непубличное</span>
            </Checkbox>
          </div>
        </div>
        <Input
          name="format"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="Формат занятий (онлайн/офлайн)"
        />
        <Input
          name="training"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="Гонка/тренировка"
        />
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <div className="flex justify-center py-4">
          <Button type="submit">Создать событие</Button>
        </div>
      </form>
    </Form>
  );
};
