import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/ui-kit/alert-dialog";
import { Button } from "@/ui-kit/button";
import { FC } from "react";

type AddToTeamDialogProps = {
  disabled?: boolean;
  onAddToTeamHandler: () => void;
};

export const AddToTeamDialog: FC<AddToTeamDialogProps> = ({
  onAddToTeamHandler,
  disabled = false,
}) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="link" className="text-black bt3" disabled={disabled}>
          Добавить в команду
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Вы действительно хотите добавить спортсмена в команду?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Отмена</AlertDialogCancel>
          <AlertDialogAction onClick={onAddToTeamHandler}>
            Добавить
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
