import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { Input } from "@/components/rhf/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { athleteMainSchema } from "@/schemas/athlete.schema";
import { Athlete, AthleteSummaryCard } from "@/types/athlete";
import { Image } from "@/icons/image";
import { MessageIcon } from "@/icons/message";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAthlete } from "@/hooks/api/use-athlete";
import { AthleteChangePassword } from "../dialog/athlete-change-password";

type EditAthleteMainProfileFormProps = {
  defaultValues?: AthleteSummaryCard;
};

export const EditAthleteMainProfileForm: FC<
  EditAthleteMainProfileFormProps
> = ({ defaultValues }) => {
  const { updateAthlete } = useAthlete();

  const form = useForm<Athlete>({
    resolver: zodResolver(athleteMainSchema),
    defaultValues: {
      ...defaultValues,
      location: defaultValues?.location.position,
    },
  });

  const onSubmit = async (values: Athlete) => {
    try {
      updateAthlete({
        ...defaultValues,
        ...values,
        sportIds: values.sportIds || [],
      });
      console.log("values: ", values);
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  console.log("form.formState.errors", form.formState.errors);

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex relative">
          {form.getValues().avatar && (
            <Image
              src={form.getValues().avatar}
              className="absolute left-2/4 -translate-x-2/4 w-16 z-0 top-2/4 -translate-y-2/4"
              alt="Avatar"
            />
          )}
          <Input
            name="avatar"
            type="file"
            itemClassName="m-auto z-10 relative"
            labelClassName="font-bold"
            placeholder="Аватар"
            onValueChange={(file) => {
              console.log("avatar prop updated with: ", file);
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            name="firstName"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="Имя"
          />
          <Input
            name="lastName"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="Фамилия"
          />
        </div>
        <Input
          name="email"
          controlElementClassName="mt-4"
          labelClassName="font-bold"
          placeholder="Email"
          startAdornment={<MessageIcon className="text-primary" />}
        />
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <AthleteChangePassword />
        <div className="flex justify-center gap-2.5 py-4">
          <Button type="submit">Сохранить</Button>
          <Button variant="outlineMain" type="reset" asChild>
            <Link to="/profile">Отмена</Link>
          </Button>
        </div>
      </form>
    </Form>
  );
};
