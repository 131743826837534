import { Outlet } from "react-router-dom";
import { NavigationPaths } from "@/components/navigation-paths";
import { useRecoilValue } from "recoil";
import { meState } from "@/atoms/account/me";

const ATHLETE_PATHS = [
  { title: "Профиль", path: "/profile" },
  { title: "События", path: "/profile/events" },
  { title: "Моя команда", path: "/profile/team" },
  { title: "Другое", path: "/profile/other" },
];

const ORGANIZATION_PATHS = [
  { title: "Профиль", path: "/profile" },
  { title: "События", path: "/profile/events" },
  { title: "Другое", path: "/profile/other" },
];

const ProfileLayout = () => {
  const me = useRecoilValue(meState);

  const isOrganization = me?.role === "ORGANIZATION";

  return (
    <div className="bg-white w-full min-h-full">
      <div className="m-auto flex flex-col my-12 content-padding-64">
        <NavigationPaths
          list={isOrganization ? ORGANIZATION_PATHS : ATHLETE_PATHS}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default ProfileLayout;
