import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import { SPORT_LABEL } from "@/consts/sports";
import { useSport } from "@/hooks/api/use-sport";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

type SportLabelKey = keyof typeof SPORT_LABEL

export const useSportDictionary = () => {
    const [sportDictionary, setSportDictionary] = useRecoilState(sportDictionaryState);
    const { getAllSports } = useSport();

    useEffect(() => {
        const fetchSports = async () => {
            const sportsList = await getAllSports();
            const sportsMap = new Map(
                sportsList.map(sport => [sport.id, { label: SPORT_LABEL[sport.name as SportLabelKey], name: sport.name, value: sport.id }])
            );
            setSportDictionary(sportsMap);
        };
        fetchSports();
    }, []);

    return sportDictionary;
}