import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { EditAthleteMainProfileForm } from "../forms/edit-athlete-main-profile-form";
import { meState } from "@/atoms/account/me";
import { useRecoilValue } from "recoil";
import { AthleteSummaryCard } from "@/types/athlete";
import { AccountRole } from "@/types/account-role";
import { EditOrganizationMainProfileForm } from "../forms/edit-organization-main-profile-form";
import { OrganizationSummaryCard } from "@/types/organization";

export const MainProfileEditCard = () => {
  const me = useRecoilValue(meState);

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] my-8 mx-auto rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать профиль</CardTitle>
      </CardHeader>
      <CardContent>
        {me ? (
          me.role === AccountRole.ORGANIZATION ? (
            <EditOrganizationMainProfileForm
              defaultValues={me as OrganizationSummaryCard}
            />
          ) : (
            <EditAthleteMainProfileForm
              defaultValues={me as AthleteSummaryCard}
            />
          )
        ) : (
          <div>Данные не загружены</div>
        )}
      </CardContent>
    </Card>
  );
};
