import { useHttpClient } from "@/hooks/use-http-client";
import {
    AthleteTeamControllerApi,
} from "@/api/generated";

export const useAthleteTeam = () => {
    const httpClient = useHttpClient();

    const controller = new AthleteTeamControllerApi(undefined, undefined, httpClient);


    const addAthleteToTeam = async (athleteId: number) => {
        try {
            const response = await controller.addAthleteToTeam(athleteId);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const removeAthleteFromTeam = async (athleteId: number) => {
        try {
            const response = await controller.deleteAthleteFromTeam(athleteId);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    const getAthleteTeam = async () => {
        try {
            const response = await controller.getAthleteTeam();
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    return {
        addAthleteToTeam,
        removeAthleteFromTeam,
        getAthleteTeam
    };
};
