import { z } from "zod";
import { cutBase64Header } from '@/schemas/utils';

export const organizationSchema = z.object({
  email: z.string(),
  name: z.string(),
  location: z
    .object({
      latitude: z.number(),
      longitude: z.number(),
    }),
  sportIds: z.array(z.number()),
  description: z.string(),
  avatar: z
    .string()
    .transform(cutBase64Header)
    .optional(),
});
