import { useHttpClient } from '@/hooks/use-http-client';
import { OrganizationControllerApi } from '@/api/generated';
import { Organization } from '@/types/organization';

export const useOrganization = () => {
  const httpClient = useHttpClient();

  const controller = new OrganizationControllerApi(undefined, undefined, httpClient);

  const organizationMe = async () => {
    try {
      return (await controller.organizationMe()).data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrganization = async (organization: Organization) => {
    try {
      const response = await controller.updateOrganization(organization);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  return {
    organizationMe,
    updateOrganization,
  }
};
