import { AthleteListItemCard } from '@/types/athlete';
import { useEffect, useState } from 'react';
import { useAthleteTeam } from './api/use-athlete-team';
import { useRecoilValue } from 'recoil';
import { sportDictionaryState } from '@/atoms/sport/sport-dictionary';
import { GetAvatarCategoryEnum } from '@/api/generated';
import { useAvatar } from './api/use-avatar';

export const useAthleteTeamData = () => {
    const [data, setData] = useState<AthleteListItemCard[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const sportDictionary = useRecoilValue(sportDictionaryState);
    const { byCategoryAndIdUrl } = useAvatar();
    const { getAthleteTeam } = useAthleteTeam();

    const fetchData = async () => {
        setIsLoading(() => true);
        const data = await getAthleteTeam();
        console.log('DATA: ', data);

        if (data) {
            const promisesList = data?.map(async (athlete) => ({
                id: athlete.id,
                firstName: athlete.firstName,
                lastName: athlete.lastName,
                sports: athlete.sportIds.map((sportId) => sportDictionary.get(sportId)?.name || ''),
                location: athlete.location,
                avatar: await byCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
            })) || [];

            const athletes: AthleteListItemCard[] = await Promise.all(promisesList);
            console.log('athletesData: ', athletes);

            setData(athletes);
        }
        setIsLoading(false);
    };

    // TODO think about reworking to atom effects
    useEffect(() => {
        fetchData();
    }, [sportDictionary]);

    return {
        data,
        isLoading,
    };
}