import { Button } from "@/ui-kit/button";
import { Form, FormMessage } from "@/ui-kit/form";
import { Input } from "@/components/rhf/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useAccount } from "@/hooks/api/use-account";
import { athleteSchema } from "@/schemas/athlete.schema";
import { Athlete } from "@/types/athlete";
import { accountState } from "@/atoms/account/account";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Image } from "@/icons/image";
import { Dropdown } from "@/components/dropdown";
import { sportDictionaryState } from "@/atoms/sport/sport-dictionary";
import { accountRoleState } from "@/atoms/account/account-role";
import { AccountRole } from "@/types/account-role";
import { LocationInput } from "../rhf/location-input";
import { LocationIcon } from "@/icons/location";
import { useLocationLabelData } from "@/hooks/use-location-label-data";
import { useEffect } from "react";

export const CreateAthleteForm = () => {
  const navigate = useNavigate();
  const { registerAthlete } = useAccount();
  const sportDictionary = useRecoilValue(sportDictionaryState);
  const [account, setAccount] = useRecoilState(accountState);
  const accountRole = useRecoilValue(accountRoleState);
  const form = useForm<Athlete>({
    resolver: zodResolver(athleteSchema),
    defaultValues: account,
  });

  const { locationName, isLoading } = useLocationLabelData(form);

  const onSubmit = async (values: Athlete) => {
    try {
      await registerAthlete({
        ...account,
        ...values,
        isCoach: accountRole === AccountRole.TRAINER,
      });
      return navigate("/login");
    } catch (error) {
      console.log(error);
      form.setError("root", { message: "Произошла ошибка" });
    }
  };

  const [avatar, firstName, lastName, sportIds, location, description] =
    form.watch([
      "avatar",
      "firstName",
      "lastName",
      "sportIds",
      "location",
      "description",
    ]);

  useEffect(() => {
    setAccount({ ...account, ...form.getValues() });
  }, [avatar, firstName, lastName, sportIds, location, description]);

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex relative">
          {form.getValues().avatar && (
            <Image
              src={form.getValues().avatar}
              className="absolute left-2/4 -translate-x-2/4 w-16 z-0 top-2/4 -translate-y-2/4"
              alt="Avatar"
            />
          )}
          <Input
            name="avatar"
            type="file"
            itemClassName="m-auto z-10 relative"
            labelClassName="font-bold"
            placeholder="Аватар"
            onValueChange={(file) => {
              console.log("avatar prop updated with: ", file);
            }}
          />
        </div>
        <div className="flex gap-4">
          <Input
            name="firstName"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="Имя"
          />
          <Input
            name="lastName"
            controlElementClassName="mt-2"
            labelClassName="font-bold"
            placeholder="Фамилия"
          />
        </div>
        <Dropdown
          name="sportIds"
          isMulti
          placeholder="Вид спорта"
          className="mt-2"
          options={Array.from(sportDictionary.values())}
          onChange={(selected) => {
            form.setValue(
              "sportIds",
              selected.map((option) => option.value),
            );
          }}
        />
        <LocationInput
          name="location"
          valueLabel={locationName}
          isLoading={isLoading}
          controlElementClassName="mt-2"
          placeholder="Локация/Место"
          startAdornment={<LocationIcon className="w-4 h-4 !top-2" />}
        />
        <Input
          name="description"
          controlElementClassName="mt-2"
          labelClassName="font-bold"
          placeholder="О себе"
        />
        {form.formState.errors.root && (
          <FormMessage className="self-center">
            {form.formState.errors.root.message}
          </FormMessage>
        )}
        <div className="flex justify-center py-4">
          <Button type="submit">Зарегистрироваться</Button>
        </div>
      </form>
    </Form>
  );
};
