import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation } from './api/use-location';
import { Position } from '@/api/generated';

// TODO better naming
export const useLocationLabelData = (form: UseFormReturn<{ location: Position } & any>) => {
    const [locationName, setLocationName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { getName } = useLocation();
    const location = form.watch('location');


    useEffect(() => {
        const getLocationName = async () => {
            setIsLoading(() => true);
            if (location) {
                const name = await getName(location);

                console.log("locationName: ", locationName);

                setLocationName(name);
            }
            setIsLoading(() => false);
        };

        getLocationName();
    }, [location]);

    return {
        locationName,
        isLoading,
    }
}