import { Button } from "@/ui-kit/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/ui-kit/dialog";
import { Textarea } from "@/ui-kit/text-area";
import { FC } from "react";

type AthleteInviteDialogProps = {
  disabled?: boolean;
};

export const AthleteInviteDialog: FC<AthleteInviteDialogProps> = ({
  disabled = false,
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button disabled={disabled}>Написать</Button>
      </DialogTrigger>
      <DialogContent className="px-14 pt-20 pb-10 max-w-[32rem]">
        <DialogHeader>
          <DialogTitle className="h5 text-center">
            Отправить спортсмену приглашение на вступление в вашу команду?
          </DialogTitle>
          <DialogClose />
        </DialogHeader>
        <Textarea placeholder="Сообщение" />
        <DialogFooter className="flex gap-2 md:justify-center">
          <DialogClose asChild>
            <Button>Отправить</Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="outlineMain">Отмена</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
