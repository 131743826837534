import { useRecoilValue } from "recoil";
import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { CreateAthleteForm } from "@/components/forms/create-athlete";
import { CreateOrganizationForm } from "@/components/forms/create-organization";
import { accountRoleState } from "@/atoms/account/account-role";
import { AccountRole } from "@/types/account-role";

export const AthleteOrOrganizationCard = () => {
  const accountRole = useRecoilValue(accountRoleState);

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
      <CardHeader>
        <CardTitle>
          <span className="h5">Информация о себе</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center px-10">
        {accountRole === AccountRole.ORGANIZATION ? (
          <CreateOrganizationForm />
        ) : (
          <CreateAthleteForm />
        )}
      </CardContent>
    </Card>
  );
};
