import {useEffect, useState} from "react";

import {GetAvatarCategoryEnum} from "@/api/generated";
import {useAvatar} from "@/hooks/api/use-avatar";
import {useRecoilValue, useResetRecoilState} from "recoil";
import {eventsSearchParamsState} from "@/atoms/event/search-params";
import {ListHook} from '@/types/list';
import {useAthlete} from '@/hooks/api/use-athlete';
import {AthleteListItemCard} from '@/types/athlete';
import {sportDictionaryState} from '@/atoms/sport/sport-dictionary';

export const useAthletesList: () => ListHook<AthleteListItemCard[]> = () => {
  const [data, setData] = useState<AthleteListItemCard[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const { byCategoryAndIdUrl } = useAvatar();
  const { getAthletes } = useAthlete();

  const sportDictionary = useRecoilValue(sportDictionaryState);
  const searchParams = useRecoilValue(eventsSearchParamsState);
  const resetSearchParams = useResetRecoilState(eventsSearchParamsState);

  const fetchData = async () => {
    setIsLoading(() => true);
    const athletesData = await getAthletes({
      page: page - 1,
      size: pageSize,
      searchParams,
    });

    const promisesList = athletesData?.data.map(async (athlete) => ({
      id: athlete.id,
      firstName: athlete.firstName,
      lastName: athlete.lastName,
      sports: athlete.sportIds.map((sportId) => sportDictionary.get(sportId)?.name || ''),
      location: athlete.location,
      avatar: await byCategoryAndIdUrl(GetAvatarCategoryEnum.Account, athlete.id),
    })) || [];

    const athletes: AthleteListItemCard[] = await Promise.all(promisesList);

    setData(athletes);
    setTotalCount(athletesData?.meta.totalCount || 0);
    setIsLoading(() => false);
  };

  // reset search params on hook unmount
  useEffect(() => {
    return () => {
      resetSearchParams();
    }
  }, [])

  // if search params changed, reset page and fetch data
  useEffect(() => {
    page === 1 ? fetchData() : setPage(1);
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [pageSize, page]);

  const pagesNum = Math.ceil(totalCount / pageSize);

  return {
    data,
    totalCount,
    isLoading,
    page,
    pageSize,
    setPage,
    setPageSize,
    pagesNum,
  };
};
