import { Tabs } from "@/components/tabs";
import { MyTeam } from "./team-tabs/team";

const ProfileTeam = () => {
  const tabs = [
    {
      value: "trainer",
      label: "Тренер",
      content: <span>Тренер</span>,
    },
    {
      value: "team",
      label: "Моя команда",
      content: <MyTeam />,
    },
    {
      value: "favorite",
      label: "Избранное",
      content: <span>Избранное</span>,
    },
  ];

  return (
    <div className="w-full">
      <Tabs
        tabs={tabs}
        defaultValue="team"
        className="mt-10"
        listClassName="gap-0"
        triggerClassName="flex-1 bt1 rounded-none border-0 border-b-2 data-[state=active]:bg-transparent data-[state=active]:text-black data-[state=active]:shadow-sm data-[state=active]:border-b-2 data-[state=active]:border-primary"
      />
    </div>
  );
};

export default ProfileTeam;
