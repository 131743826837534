import { EventList } from "@/components/list/event-list";
import { SearchWithFiltersCard } from "@/components/card/search-with-filters-card";
import { useEventList } from "@/hooks/list/use-event-list";
import { Pagination } from "@/components/pagination";

const Events = () => {
  const { data, isLoading, page, setPage, totalCount, pagesNum } =
    useEventList();

  return (
    <div className="bg-white w-full">
      <div className="content-padding-64 mt-12">
        <SearchWithFiltersCard />
      </div>
      <div className="content-padding-64 md:content-padding-56 mt-12">
        <EventList data={data} isLoading={isLoading} total={totalCount} />
        {pagesNum > 1 && (
          <Pagination total={pagesNum} onChange={setPage} current={page} />
        )}
      </div>
    </div>
  );
};

export default Events;
