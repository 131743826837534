import { Card, CardContent, CardHeader, CardTitle } from "@/ui-kit/card";
import { useParams } from "react-router-dom";
import { useEventData } from "@/hooks/use-event-data";
import { EditEventForm } from "@/components/forms/edit-event";
import { useMemo } from "react";
import { Skeleton } from "@/ui-kit/skeleton";

export const EditEventCard = () => {
  const { id } = useParams();

  const { data, isLoading } = useEventData(id!);

  const external = useMemo(
    () => Boolean(data?.externalEventUrl || data?.externalRegistrationUrl),
    [data],
  );

  return (
    <Card className="min-w-[300px] w-screen max-w-[500px] m-8 rounded-2xl">
      <CardHeader>
        <CardTitle>Редактировать событие</CardTitle>
      </CardHeader>
      <CardContent>
        {isLoading ? (
          <div className="flex flex-col">
            {external && (
              <>
                <Skeleton className="w-full h-10 mb-2" />
                <Skeleton className="w-full h-10 mb-2" />
              </>
            )}
            <Skeleton className="h-24 w-20 m-auto" />
            <div className="flex gap-4 mt-2">
              <Skeleton className="flex-1 h-10" />
              <Skeleton className="flex-1 h-10" />
            </div>
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
            <Skeleton className="w-full h-10 mt-2" />
          </div>
        ) : (
          // TODO fix type error
          /* @ts-ignore */
          data && (
            <EditEventForm
              external={external}
              defaultValues={{ ...data, location: data.location.position }}
            />
          )
        )}
      </CardContent>
    </Card>
  );
};
