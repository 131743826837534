import { useAthlete } from "@/hooks/api/use-athlete";
import { useOrganization } from "@/hooks/api/use-organization";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useMemo, useState } from "react";
import { GetAvatarCategoryEnum, Organization } from "@/api/generated";
import { accountState } from "@/atoms/account/account";
import { AccountRole } from "@/types/account-role";
import { useAvatar } from "@/hooks/api/use-avatar";
import { AthleteSummaryCard } from "@/types/athlete";
import { meState } from "@/atoms/account/me";
import { sportDictionaryState } from '@/atoms/sport/sport-dictionary';
import { accountRoleState } from '@/atoms/account/account-role';

const abbr = (value: string | undefined) => value?.at(0)?.toUpperCase() ?? "";

export const useMe = () => {
  const account = useRecoilValue(accountState);
  const [me, setMeState] = useRecoilState(meState);
  const [_, setAccountRole] = useRecoilState(accountRoleState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { athleteMe } = useAthlete();
  const { organizationMe } = useOrganization();
  const { byCategoryAndIdUrl } = useAvatar();
  const sportDictionary = useRecoilValue(sportDictionaryState);

  const fetchData = async () => {
    if (account.id) {
      // TODO think about utilizing react query for loading state, cache invalidation and so on, since this looks ugly
      setIsLoading(() => true);
      setAccountRole(account.role);

      if (account.role === AccountRole.ORGANIZATION) {
        const organization = await organizationMe();

        if (organization) {
          const avatarUrl = await byCategoryAndIdUrl(
            GetAvatarCategoryEnum.Account,
            organization.id,
          );
          setMeState({
            ...organization,
            avatar: avatarUrl,
            rating: 4,
            achievements: ["Bronze", "Silver", "Gold", "Regular"],
            sports: organization.sportIds.map((sportId) => sportDictionary.get(sportId)?.name || ''),
          });
        }
      } else {
        const athlete = await athleteMe();
        if (athlete) {
          const avatarUrl = await byCategoryAndIdUrl(
            GetAvatarCategoryEnum.Account,
            athlete.id,
          );
          setMeState({
            ...athlete,
            avatar: avatarUrl,
            rating: 4,
            achievements: ["Bronze", "Silver", "Gold", "Regular"],
            sports: athlete.sportIds.map((sportId) => sportDictionary.get(sportId)?.name || ''),
          });
        }
      }
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account.id, account.role]);

  const abbriviation = useMemo(() => {
    if (account.role === AccountRole.ORGANIZATION) {
      return abbr((me as unknown as Organization)?.name);
    } else {
      return (
        abbr((me as AthleteSummaryCard)?.firstName) +
        abbr((me as AthleteSummaryCard)?.lastName)
      );
    }
  }, [account.role, me]);

  return {
    abbriviation,
    isLoading,
    me,
  };
};
