import { meState } from "@/atoms/account/me";
import { AthleteSummary } from "@/components/athlete-summary";
import { OrganizationSummary } from "@/components/organization-summary";
import { AccountRole } from "@/types/account-role";
import { AthleteSummaryCard } from "@/types/athlete";
import { OrganizationSummaryCard } from "@/types/organization";
import { useRecoilValue } from "recoil";

const ProfileMain = () => {
  const me = useRecoilValue(meState);

  return me?.role === AccountRole.ORGANIZATION ? (
    <OrganizationSummary isOwner data={me as OrganizationSummaryCard} />
  ) : (
    <AthleteSummary data={me as AthleteSummaryCard} />
  );
};

export default ProfileMain;
