import { AthleteSummary } from "@/components/athlete-summary";
import { useAthleteData } from "@/hooks/use-athlete-data";

const Athlete = () => {
  const { data, isLoading } = useAthleteData();

  return (
    <div className="bg-white w-full content-padding-64">
      <AthleteSummary data={data} isLoading={isLoading} />
    </div>
  );
};

export default Athlete;
