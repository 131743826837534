import { AccountControllerApi, LoginResponse } from "@/api/generated";
import { Athlete } from "@/types/athlete";
import { LoginFormData } from "@/types/login";
import { RegisterFormData } from "@/types/register";
import { useHttpClient } from "@/hooks/use-http-client";
import { Organization } from '@/types/organization';

export const useAccount = () => {
  const httpClient = useHttpClient();

  const controller = new AccountControllerApi(undefined, undefined, httpClient);
  const login = async (values: LoginFormData) => {
    try {
      const response = await controller.login(values);
      return response.data;
    } catch (error) {
      console.log(error);
      return {} as LoginResponse;
    }
  };
  const emailExist = async (email: string) => {
    try {
      const response = await controller.existsByEmail({ email });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const registerAthlete = async (
    athleteData: Athlete & RegisterFormData
  ) => {
    try {
      const response = await controller.registerAthlete({
        ...athleteData,
        // TODO remove longtitue and latitude hardcoded values
        // @ts-expect-error
        location: { longitude: 0, latitude: 0, ...athleteData.location }
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const registerOrganization = async (athleteData: Organization & RegisterFormData) => {
    try {
      const response = await controller.registerOrganization({
        ...athleteData,
        location: { latitude: 0, longitude: 0 },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const me = async () => {
    try {
      return (await controller.accountMe()).data;
    } catch (error) {
      console.log(error);
    }
  };

  return {
    login,
    emailExist,
    registerAthlete,
    registerOrganization,
    me,
  };
};
