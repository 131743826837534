import {useEffect, useState} from "react";

import {GetAvatarCategoryEnum} from "@/api/generated";
import {useAvatar} from "@/hooks/api/use-avatar";
import {useEvent} from "@/hooks/api/use-event";
import {EventListItemCard} from "@/types/event";
import {useRecoilValue, useResetRecoilState} from "recoil";
import {eventsSearchParamsState} from "@/atoms/event/search-params";
import {ListHook} from '@/types/list';
import {sportDictionaryState} from '@/atoms/sport/sport-dictionary';

export const useEventList: () => ListHook<EventListItemCard[]> = () => {
  const [data, setData] = useState<EventListItemCard[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const { byCategoryAndIdUrl } = useAvatar();
  const { getEvents } = useEvent();

  const sportDictionary = useRecoilValue(sportDictionaryState);
  const searchParams = useRecoilValue(eventsSearchParamsState);
  const resetSearchParams = useResetRecoilState(eventsSearchParamsState);

  const fetchData = async () => {
    setIsLoading(() => true);
    const eventsData = await getEvents({
      page: page - 1,
      size: pageSize,
      searchParams,
    });

    const promisesList = eventsData?.data.map(async (event) => ({
      name: event.name,
      date: event.date,
      location: event.location.name || "",
      sport: sportDictionary.get(event.sportId)?.name || "",
      id: event.id,
      avatar: await byCategoryAndIdUrl(GetAvatarCategoryEnum.Event, event.id),
    })) || [];

    const events: EventListItemCard[] = await Promise.all(promisesList);

    setData(events);
    setTotalCount(eventsData?.meta.totalCount || 0);
    setIsLoading(() => false);
  };

  // reset search params on hook unmount
  useEffect(() => {
    return () => {
      resetSearchParams();
    }
  }, [])

  // if search params changed, reset page and fetch data
  useEffect(() => {
    page === 1 ? fetchData() : setPage(1);
  }, [searchParams]);

  useEffect(() => {
    fetchData();
  }, [pageSize, page]);

  const pagesNum = Math.ceil(totalCount / pageSize);

  return {
    data,
    totalCount,
    isLoading,
    page,
    pageSize,
    setPage,
    setPageSize,
    pagesNum,
  };
};
