import React from "react";

export const PlusSmallIcon = React.forwardRef(
  (
    { ...other }: React.SVGAttributes<SVGElement>,
    ref: React.ForwardedRef<SVGSVGElement>,
  ) => (
    <svg
      ref={ref}
      width="10"
      height="10"
      viewBox="0 0 22 22"
      fill="none"
      stroke="#2F2F2F"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <path
        d="M11 2V20M2 11H20"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);
