import { useHttpClient } from "@/hooks/use-http-client";
import {
  AthleteControllerApi,
  PaginatedResultAthleteSummary,
} from "@/api/generated";
import { SortablePageRequestParams } from "@/types/page";
import { Athlete, AthleteSearchParams } from "@/types/athlete";
import { EMPTY_PAGINATED_LIST } from "@/consts/list";

export const useAthlete = () => {
  const httpClient = useHttpClient();

  const controller = new AthleteControllerApi(undefined, undefined, httpClient);

  const athleteMe = async () => {
    try {
      const response = await controller.athleteMe();

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAthletes = async (
    params: SortablePageRequestParams<AthleteSearchParams>,
  ): Promise<PaginatedResultAthleteSummary> => {
    try {
      const {
        page,
        size,
        sort,
        searchParams: {
          name,
          location: { longitude, latitude } = { longitude: 0, latitude: 0 },
          sportIds,
        },
      } = params;
      const response = await controller.searchAthletes(
        latitude,
        longitude,
        page,
        size,
        sort,
        sportIds,
        name,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return EMPTY_PAGINATED_LIST;
    }
  };

  const getAthlete = async (athleteId: number) => {
    try {
      const response = await controller.getAthlete(athleteId);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateAthlete = async (athlete: Athlete) => {
    try {
      const response = await controller.updateAthlete({
        ...athlete,
        location: {
          latitude: athlete.location?.latitude || 0,
          longitude: athlete.location?.longitude || 0,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return {
    athleteMe,
    getAthletes,
    getAthlete,
    updateAthlete,
  };
};
