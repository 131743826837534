import { LocationControllerApi, Position } from '@/api/generated';
import { useHttpClient } from '@/hooks/use-http-client';

export const useLocation = () => {
    const httpClient = useHttpClient();

    const controller = new LocationControllerApi(undefined, undefined, httpClient);

    const getName = async (location: Position): Promise<string> => {
        try {
            return (await controller.reverse(location)).data.name || '';
        } catch (error) {
            console.log(error);
            return '';
        }
    };

    return {
        getName
    }
};
