import { FC, InputHTMLAttributes } from "react";
import { useFormContext } from "react-hook-form";
import { ReactHookFormField } from "@/types/rhf";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/ui-kit/form";
import { LocationInput as LocationInputComponent } from "@/components/location-input";
import { Coords } from "@/types/map";

interface LocationInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "name">,
    ReactHookFormField {
  onValueChange?: (value?: Coords | null) => void;
  defaultMapCenterCoords?: Coords;
  startAdornment?: React.ReactNode;
  valueLabel?: string;
  isLoading?: boolean;
}

export const LocationInput: FC<LocationInputProps> = ({
  name,
  itemClassName,
  label,
  labelClassName,
  controlElementClassName,
  placeholder = "",
  disabled,
  messageClassName,
  onValueChange,
  defaultMapCenterCoords,
  startAdornment,
  valueLabel,
  isLoading = false,
}) => {
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      render={({ field: { onChange, value, ...other } }) => (
        <FormItem className={itemClassName}>
          {label && <FormLabel className={labelClassName}>{label}</FormLabel>}
          <FormControl>
            <LocationInputComponent
              triggerClassName={controlElementClassName}
              defaultMapCenterCoords={defaultMapCenterCoords}
              defaultValue={value}
              placeholder={placeholder}
              startAdornment={startAdornment}
              label={valueLabel}
              onValueChange={(coords) => {
                onChange(coords);
                onValueChange && onValueChange(coords);
              }}
              isLoading={isLoading}
            />
          </FormControl>
          <FormMessage className={messageClassName} />
        </FormItem>
      )}
    />
  );
};

export type { LocationInputProps };
