import { CloseIconSmall } from "@/icons/close-small";
import { Button } from "@/ui-kit/button";
import { cn } from "@/utils/tailwind";
import StateManagedSelect, { components } from "react-select";
import Select from "react-select";
import { Check } from "lucide-react";
import { PlusSmallIcon } from "@/icons/plus-small";

type DropdownProps = StateManagedSelect;

// TODO find proper typing for this one.
const CustomClearIndicator = ({ innerProps }: any) => (
  <div {...innerProps}>
    <Button type="button" variant="ghost" size="icon">
      <CloseIconSmall />
    </Button>
  </div>
);

const CustomDropdownIndicator = ({ innerProps }: any) => (
  <div {...innerProps}>
    <Button type="button" variant="ghost" size="icon">
      <PlusSmallIcon />
    </Button>
  </div>
);

const CustomOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="flex w-full py-[2.5px]">
        <div
          className={cn(
            "peer h-4 w-4 shrink-0 rounded-sm border border-input ring-offset-background",
            // { "text-black": props.isSelected },
          )}
        >
          <div className={cn("flex items-center justify-center text-current")}>
            {props.isSelected && <Check className="h-4 w-4" />}
          </div>
        </div>
        <span className="ml-2">{props.data.label}</span>
      </div>
    </components.Option>
  );
};

export const Dropdown: DropdownProps = ({
  name,
  isMulti,
  placeholder,
  onChange,
  options,
  className,
  isClearable = true,
}) => {
  console.log(options);
  return (
    <Select
      isClearable={isClearable}
      closeMenuOnSelect={false}
      name={name}
      isMulti={isMulti}
      hideSelectedOptions={false}
      classNames={{
        control: () => "pl-5 !rounded-xl !border-blue-200 text-l !min-h-10",
        placeholder: () => "ml-2 ft1",
        option: ({ data, isDisabled, isFocused, isSelected }) => {
          return cn("text-left !pl-4 ft1 !text-card-foreground !py-0", {
            "!bg-transparent": isSelected || isFocused,
          });
        },
        valueContainer: () => "pl-2 text-left",
        multiValue: () => "-ml-2 ft1 bg-transparent",
        multiValueLabel: () => "ml-2 ft1 bg-transparent",
        multiValueRemove: () => "ml-2 ft1 bg-transparent",
        singleValue: () => "ml-2 ft1 bg-transparent",
        menuList: () => "!py-2.5",
        menu: () => "!border-blue-200 !rounded-xl",
        indicatorSeparator: () => "hidden",
      }}
      placeholder={placeholder}
      onChange={(...val) => {
        onChange?.(...val);
      }}
      options={options}
      className={className}
      components={{
        ClearIndicator: CustomClearIndicator,
        Option: CustomOption,
        DropdownIndicator: CustomDropdownIndicator,
      }}
    />
  );
};
